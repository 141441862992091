ion-modal {
  &,
  &.modal-default.show-modal ~ ion-modal.modal-default {
    --width: 25.5rem;
    --height: auto;
    --min-height: auto;
    --border-radius: 0.75rem;
    --backdrop-opacity: 0.7;
    --max-width: 100vw;
    --background: var(--White, #fff);

    --box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    &::part(content) {
      max-height: 100vh;
      overflow-y: auto;
    }
  }
  &.full-modal::part(content) {
    --width: 100%;
    --height: 100%;
  }
  &.xxl-modal::part(content) {
    --width: 80%;
  }
  &.xl-modal::part(content) {
    --width: 50rem;
  }
  &.lg-modal::part(content) {
    --width: 36rem;
  }
  &.sm-modal::part(content) {
    --width: 25rem;
  }
  &.image-slider {
    --max-width: auto;
    &::part(content) {
      --border-radius: 0;
      --background: transparent;
      --box-shadow: none;
      --height: 100%;
      --width: 100%;
    }
  }
}
