ion-select.hes-select {
  color: #838799;
  --background: #f6f7f9;
  --border-color: none;
  border-radius: 0.25rem;
  --border-radius: 0.25rem;
  --padding-start: 1.1rem;
  --padding-end: 1.1rem;
  --padding-top: 0.625rem;
  --padding-bottom: 0.625rem;
  --highlight-color: var(--h-color-primary-400);
  &.select-expanded {
    border: 1px solid var(--h-color-primary-50, #fff9c1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  }
  min-height: 3.14rem;
  height: 3.14rem;
  box-sizing: content-box;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  &::part(icon) {
    top: 0;
  }
  &::part(placeholder),
  &::part(container) {
    color: #838799;
    font-size: 0.875rem;
    font-style: italic;
    font-weight: 400;
    line-height: 1.125rem;
    text-transform: capitalize;
  }
}

ion-select.hes-select--lang {
  border-radius: 9999px;
  --border-radius: 9999px;
  --padding-start: 0.88rem;
  --padding-end: 0.88rem;
  --padding-top: 0.5rem;
  --padding-bottom: 0.5rem;
  min-height: unset;
  height: auto;
  &::part(container) {
    text-align: start;
  }
}

.select-disabled {
  opacity: 0.8;
}

ion-popover.select-popover {
  --offset-y: 0.5rem;
  ion-select-popover {
    ion-list.sc-ion-select-popover-md {
      &.list-md,
      &.list-ios {
        background-color: white;
      }

      ion-item {
        --background-focused: #f9fafb;
        --background-hover: #f9fafb;
        --background-focused-opacity: 1;
        --background-hover-opacity: 1;
        --background: white;
        &.item-radio-checked {
          --background-focused: #f9fafb;
          --background-hover: #f9fafb;
          --background-focused-opacity: 1;
          --background-hover-opacity: 1;
          --background: white;
        }

        ion-radio.sc-ion-select-popover-md.radio-checked {
          &::part(container) {
            opacity: 1;
            border: none;
            width: 1.25rem;
            height: 1.25rem;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%23ecad01" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 1rem;
          }
          &::part(mark) {
            display: none;
          }
        }

        ion-checkbox {
          text-transform: capitalize;
          --border-color: #d0d5dd;
          --border-color-checked: var(--h-color-primary-400);
          --border-radius: 0.35rem;
          --border-style: solid;
          --border-width: 1px;
          --checkbox-background: #fff;
          --checkbox-background-checked: var(--h-color-primary-25);
          --size: 1.25rem;
          color: #344054;
          &.checkbox-checked::part(container) {
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path fill="%23ecad01" d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"/></svg>');
            background-repeat: no-repeat;
            background-position: center;
            background-size: 0.75rem;
          }
          &::part(mark) {
            display: none;
          }
        }
      }
    }
    overflow: auto;
  }
  &::part(content) {
    border-radius: 0.5rem;
  }
}
