ion-radio.hes-radio {
  --color-checked: var(--h-color-primary-400);
  --color: var(--h-color-gray-300);

  &:hover {
    --color: var(--color-checked);
    &::part(container) {
      background-color: #fffce7;
    }
  }

  &:focus,
  &:active {
    --color: var(--h-color-primary-100);
    &::part(container) {
      box-shadow: 0px 0px 0px 4px var(--h-color-primary-25);
    }
  }

  &::part(label) {
    color: #344054;
    /* Text md/Medium */
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem; /* 150% */
  }

  // remove default ion focus ring
  &::part(container)::after {
    width: 0;
  }

  &.radio-checked {
    &::part(mark) {
      width: 6px;
      height: 6px;
    }
    &::part(container) {
      background-color: #fffce7;
    }
  }

  &.hes-radio--sm {
    &::part(container) {
      width: 1rem;
      height: 1rem;
    }
    &::part(label) {
      margin-inline-start: 0.5rem;
    }
  }
  &.hes-radio--md {
    &::part(container) {
      width: 1.25rem;
      height: 1.25rem;
    }
    &::part(label) {
      margin-inline-start: 0.75rem;
    }
  }
}
