ion-popover.hes-menu {
  --width: 15rem;
  --background: #fff;
  --offset-y: 0.75rem;

  &::part(content) {
    border-radius: 0.5rem;
    border-color: var(--h-color-gray-100);
    border-width: 1px;
  }

  ion-item {
    --padding-start: 0.9rem;
    --padding-end: 0.9rem;
    --padding-top: 0.62rem;
    --padding-bottom: 0.62rem;
    --border-color: var(--h-color-gray-100);
    --border-width: 0.0625rem;

    &::part(native) {
      --min-height: auto;
    }
  }
}
