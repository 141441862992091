.vjs-hessa.video-js {
  color: #fff;
  .vjs-control-bar {
    height: 6rem;
    padding: 2.5rem 1rem;
    flex-wrap: wrap;
    background: linear-gradient(
      180deg,
      rgba(8, 9, 13, 0) 0%,
      rgba(8, 9, 13, 0.9) 80.37%
    );
    backdrop-filter: blur(1px);
    .vjs-play-control {
    }
    .vjs-progress-control {
      position: absolute;
      bottom: 5rem;
      right: 0;
      left: 0;
      width: 100%;
      height: 0.125rem;
      .vjs-progress-holder {
        border-radius: 1.25rem;
        @media (max-width: 1024px) {
          height: 10px;
        }
      }
      .vjs-load-progress {
        opacity: 0.4;
        background: #d9d9d9;
      }

      .vjs-play-progress.vjs-slider-bar {
        border-radius: 1.25rem;
        &:before {
          display: none;
        }
      }
    }
    .vjs-remaining-time {
      display: none;
    }
    .vjs-picture-in-picture-control {
      display: none;
    }
    .vjs-skip-forward-10 {
      margin-right: auto;
    }
  }
}
