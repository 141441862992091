/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&family=Nunito:wght@200;300;400;500;600;700;800;900&display=swap");
@import "@taiga-ui/core/styles/taiga-ui-local.scss";
/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

@import url("https://unpkg.com/leaflet@1.7.1/dist/leaflet.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  [type="text"]:focus,
  input:where(:not([type])):focus,
  [type="email"]:focus,
  [type="url"]:focus,
  [type="password"]:focus,
  [type="number"]:focus,
  [type="date"]:focus,
  [type="datetime-local"]:focus,
  [type="month"]:focus,
  [type="search"]:focus,
  [type="tel"]:focus,
  [type="time"]:focus,
  [type="week"]:focus,
  [multiple]:focus,
  textarea:focus,
  select:focus {
    @apply ring-0;
  }
}

@layer components {
  .hes-card {
    @apply rounded-2xl border border-solid border-[#F1F1F4] bg-white px-4 py-6 shadow-sm lg:rounded-lg lg:p-8;
  }

  .hes-inital {
    @apply flex h-10 w-10 items-center justify-center rounded-full bg-blue-50 py-2 text-center text-base font-medium;
  }

  .hes-badge {
    @apply flex w-fit items-center justify-center rounded-2xl px-2 py-0.5 text-xs font-medium leading-none;

    &--warnning {
      @apply bg-primary-25 text-primary-400;
    }
    &--success {
      @apply bg-success-50 text-success-700;
    }
  }

  .text-truncate-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Number of lines you want */
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@layer utilities {
  .capitalize-first {
    @apply normal-case first-letter:capitalize;
  }
  .centered-flex {
    @apply flex items-center justify-center;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-UltraLight.ttf") format("truetype");
  font-weight: 200; // Extra Light (Ultra Light)
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNEXTLTARABIC-LIGHT.ttf") format("truetype");
  font-weight: 300; // Light
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-Regular.ttf") format("truetype");
  font-weight: normal; //Normal
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-Medium.ttf") format("truetype");
  font-weight: 500; // Medium
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-Bold.ttf") format("truetype");
  font-weight: 700; // Bold
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-Heavy.ttf") format("truetype");
  font-weight: 800; // extra bold
  font-style: normal;
}
@font-face {
  font-family: "Din";
  src: url("/assets/fonts/DINNextLTArabic-Black.ttf") format("truetype");
  font-weight: 900; // Black
  font-style: normal;
}

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
// @import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";

@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

// regular style toast
@import "ngx-toastr/toastr";
.toast-container {
  @apply max-w-2xl;
}
.toast-info,
.toast-error,
.toast-success,
.toast-warning {
  background: unset;
  box-shadow: none;
}
.toast-container .ngx-toastr {
  box-shadow: none;
}
.toast-container .ngx-toastr:hover {
  box-shadow: none;
}

ion-textarea.hes-textarea {
  --background: #f6f7f9;
  --border-radius: 0.25rem;
  --padding-end: 1rem;
  --padding-start: 1rem;
  --padding-top: 0.75rem;
  --placeholder-color: #838799;

  &.sc-ion-textarea-md-h {
    --padding-bottom: 0.75rem;
  }
  textarea {
    height: 8rem;
  }
}

ion-datetime {
  --ion-color-primary: var(--h-color-primary-400);
}

ion-spinner {
  color: var(--h-color-primary-400);
}

ion-popover {
  &.hes-school-structure-control {
    --min-width: 24rem;
    --max-height: 30rem;
  }
}

ion-modal {
  &.hes-school-structure-control-mobile {
    --height: 80%;
    --width: 98%;
  }
  &.modal-full::part(content) {
    --height: 100%;
    --width: 100%;
  }
  &.radius-none {
    --border-radius: 0;
  }
}

// CometChat
.cc-conversations {
  border: none !important;
  direction: ltr !important;
}
.cc-messages {
  border: none !important;
}
.cc-message-header__wrapper {
  padding: 1.56rem !important;
  border-bottom: 1px solid #eaecf0 !important;
}
.list__header {
  margin-bottom: 0 !important;
}
.cc__icon {
  background: #272525 !important;
}
.cc-message-list {
  margin-top: 1rem !important;
}

ion-popover {
  &.hes-reaction-popover {
    --background: transparent;
    --box-shadow: none;
    --max-width: 15rem;
  }
}

ion-content,
ion-header,
ion-footer,
ion-toolbar {
  &.bg-white {
    --background: #fff;
  }
}

[data-appearance="hes-editor"] {
  &:after {
    border-color: #f6f7f9;
  }
}
ion-segment {
  --background: #fff;
  gap: 9px;
  ion-segment-button {
    background-color: #f9f9f9;
    &.active {
      background-color: #ecad01;
      color: #fff;
    }
    &::part(indicator) {
      display: none;
    }
  }
}

ion-skeleton-text {
  animation-duration: 1.5s;
}

.phone-number-dir {
  direction: ltr;
  flex-direction: row-reverse;
}

swiper-container {
  --swiper-pagination-bullet-inactive-color: var(
    --ion-text-color-step-800,
    #cccccc
  );
  --swiper-pagination-color: var(--ion-color-primary, #0054e9);
  --swiper-pagination-progressbar-bg-color: rgba(
    var(--ion-text-color-rgb, 0, 0, 0),
    0.25
  );
  --swiper-scrollbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.1);
  --swiper-scrollbar-drag-bg-color: rgba(
    var(--ion-text-color-rgb, 0, 0, 0),
    0.5
  );
  width: 100%;
  height: 100%;
}

swiper-slide {
  display: flex;
  position: relative;

  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  font-size: 18px;

  text-align: center;
  box-sizing: border-box;
}

.swiper-slide {
  img,
  ion-img {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
}

// margin above and below youtube video in hes editor.
// so that user can press above or below and write without deleting it
div[data-youtube-video] {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.ios-header-padding {
  padding-top: var(--ion-safe-area-top);
}

.transparent-modal {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3) !important;
  --background: transparent !important;

  .modal-wrapper {
    background-color: rgba(0, 0, 0, 0.3) !important;
    --background: transparent !important;
  }

  .danger-row {
    background-color: #fef3f2 !important;
  }
}
